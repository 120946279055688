.experience-component.experience-assets-2up_banner {
  picture {
    display: block;
  }

  img {
    display: block;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .pd-2up-banner {
      display: flex;
      justify-content: space-between;
    }

    .element-1,
    .element-2 {
      width: calc(50% - 20px);
    }
  }

  @media screen and (max-width: 767px) {
    .element-1 {
      padding-bottom: 30px;
    }
  }

  .pd-banner-2up-description {
    text-align: left;
    padding: 10px;
    position: absolute;
    bottom: 8%;
    left: 8%;
    z-index: 3;

    @include respond(largeUp) {
      bottom: 12%;
      left: 20%;
    }

    .text-white {
      color: $white;
    }

    h3 {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    h2 {
      margin-bottom: 3px;
      font-size: 36px;

      @include respond(largeUp) {
        font-size: 42px;
      }

      @media screen and (min-width: $small-breakpoint) {
        margin-bottom: 0;
      }
    }

	  .pd-cta-buttons {

		  margin: 15px 0;

		  &:nth-of-type(1) {
			  margin-right: 5px;
			  margin-left: -5px;
			  position: relative;
		  }

		  .link {
			  padding: 5px;

			  &:hover {
				  text-decoration: none;
				  background: $white;
				  color: $black;
			  }

			  &.inverted {
				  &:hover {
					  text-decoration: none;
					  background: $black;
					  color: $white;
				  }
			  }
		  }
	  }
  }
}
