.pd-my-joys-global {
	padding: 0 14px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	@include respond(largeUp) {
		flex-direction: row;
		padding: 0;
	}

	.pd-my-joys-global-left {
		flex-basis: 100%;
		position: relative;

		@include respond(largeUp) {
			flex-basis: 40%;
			width: 40%;
		}

		.pd-my-joys-image {
			object-fit: cover;
			height: 100%;
		}
	}

	.pd-my-joys-global-left-content-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0 40px 40px 40px;
		display: none;
		@include respond(largeUp) {
			display: block;
		}
	}

	.pd-my-joys-global-left-content,
	.pd-my-joys-mobile-buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		@include respond(largeUp) {
			justify-content: flex-start;
		}

		.button {
			font-family: $font-neuzeit;
			font-size: 15px;
			font-weight: bold;
			line-height: 42px;
			height: 44px;
			padding: 0 24px;
			text-transform: uppercase;
			text-decoration: none;
			margin: 0 5px 10px 5px;
			@include respond(largeUp) {
				margin: 0 10px 10px 0;
			}
		}
	}

	.pd-my-joys-global-right {
		flex-basis: 100%;
		display: flex;
		flex-direction: column;

		@include respond(largeUp) {
			flex-basis: 60%;
			width: 60%;
		}

		.pd-my-joys-global-right-content {
			text-align: center;

			@include respond(largeUp) {
				padding-left: 20px;
				text-align: left;
			}
		}
		.pd-my-joys-heading {
			text-transform: capitalize;
			font-family: $font-neuzeit;
			color: $black;
			margin: 0;
			padding: 30px 0;
			font-weight: 700;
			font-size: 18px;
		}

		.pd-my-joys-sub-heading {
			font-weight: 400;
			font-size: 14px;
			margin: 0;
			padding: 0 0 35px 0;
			font-family: $font-neuzeit;
			color: $black;
		}
	}

	.pd-my-joys-static-img-desktop {
		display: none;
		@include respond(largeUp) {
			width: 181px;
			height: 88px;
			object-fit: contain;
			display: block;
		}
	}

	.pd-my-joys-static-img-mobile {
		width: 138px;
		height: 67px;
		object-fit: contain;
		position: absolute;
		top: 25px;
		right: 30px;
		@include respond(largeUp) {
			display: none;
		}
	}

	.pd-my-joys-mobile-buttons {
		display: flex;
		padding-bottom: 20px;
		@include respond(largeUp) {
			display: none;
		}
	}

	.slick-arrow {
		@include respond(largeUp) {
			top: calc(50% - 35px) !important;
		}
	}

	.slick-slider {
		margin-bottom: 0;
	}

	.pd-my-joys-iframe {
		aspect-ratio: 1/1;
		width: 100%;
		border: 0;
	}

	.pd-my-joys-global-left-image {
		height: 100%;
	}
}
