//////////////////////
// Homepage Styles
//////////////////////

#homepage-slider {
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 44vw;
	clear: both;

	margin: 0;
	padding: 0;

	ul#homepage-slides{
		margin: 0;
		padding: 0;
		position: relative;

		/*
		* Fix for bug where phantom slides are added when you switch in-between
		* breakpoints, and have a responsive slick setup that conditionally shows arrows
		*/
		@include respond(small){
			.slick-arrow {
				display: none !important;
			}
		}

		li{
			display: inline-block;
			position: relative;

			& > img{ // the background image
				width: 100vw;
				float: left;
			}
			@include respond(small){
				&.slick-slide {
					//height: 506px;
				}
			}
		}
	}
}

.banner-message{
	color: $gray-warm;
	@extend %color-by-class;
	position: absolute;
	right: 4.6vw;
	top: 2.3vw;
	width: 39vw;
	text-align: left;
	text-justify: inter-word;
	font-family: $titillium;
	text-shadow: 0px 3px 40px rgba(255, 255, 255, 0.2);
	text-align: left;
	font-size: 1.35vw;
	line-height: 1.5em;
	&.left{
		right: auto;
		left: 4.6vw;
	}

	@include respond(small){
		position: static;
		font-size: 3.75vw;
		color: $gray-warm;
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		padding: 4.5vw;
		clear: both;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		&.left{
			right: auto;
			left: 0;
		}
	}
	@include respond(large){
		&.left{
			right: auto;
			left: 4.6vw;
		}
	}

	img{
		width: auto;
		margin-bottom: 1em;
		@include respond(small){
			margin: 10px auto;
			width: 33%;
			display: inline-block;
		}
	}

	.banner-text1{
		color: $gray-dark;
		@extend %color-by-class;
		text-transform: uppercase;
		text-align: left;
		font-size: 7.2vw;
		line-height: 1em;
		font-weight: bold;
		font-family: $oswald;
		letter-spacing: .05em;

		@include respond(small){
			font-size: 12.5vw;
			text-align: center;
		}

		a{
			color: $gray-dark;
			@extend %color-by-class;
		}

	}
	.banner-text2{
		color: $green;
		@extend %color-by-class;
		text-transform: uppercase;

		letter-spacing: .1em;
		text-align: left;
		font-size: 1.67vw;
		font-family: $oswald;
		line-height: 1em;

		@include respond(small){
			font-size: 4.4vw;
			text-align: center;
		}
		a{
			color: $green;
			@extend %color-by-class;
		}
	}
	.banner-text3{
		color: $green;
		@extend %color-by-class;
		text-transform: uppercase;
		text-align: left;
		font-size: 3.7vw;
		font-weight: bold;
		font-family: $oswald;
		line-height: 1em;
		margin-bottom: 26px;
		@include respond(small){
			font-size: 9.4vw;
			text-align: center;
		}

		a{
			color: $green;
			@extend %color-by-class;
		}
	}

	.buttonRow{
		margin-top: 26px;
	}
	button{
		float: left;
		padding: 0 1.8em;
		&:hover{
			border-color: $black;
		}
		@include respond(small){
			float: none;
			height: 34px;
			line-height: 30px;
		}
	}
	a.more{
		color: $gray-warm;
		@include color-by-class(); //not sure why @extend doesn't work here
		display: block;
		float: left;
		margin-left: 0;
		padding: 0 1vw;
		font-weight: bold;
		font-size: 11px; //px instead of vw's to sync with the button next to it
		line-height: 34px;
		width: auto;
		letter-spacing: 1px;
		text-transform: uppercase;
		text-align: center;

		@include respond(small){
			float: none;
			display: inline;
			margin-left: 5vw;
			padding: 1vw;
			line-height: initial;
		}

		@include respond(standard){
			// match button height and font size
			font-size: 14px;
			line-height: 44px;
		}
	}
	div, span, a, p, h1, h2, h3, h4, h5, h6 {
		@extend %color-by-class;
	}
}

.home-bottom-slots {
	margin: auto;
	width: 100%;
	clear: both;

	h2{
		text-align: center;
	}

	.promoSlotFull{
		@extend %color-by-class;
		width: 100%;
		float: none;
		clear: both;
		button{
			@include respond(small){
				height: 34px;
				line-height: 30px;
			}
		}
		&#home-full-1{
			@extend %color-by-class;
			.bannerContent{
				@extend %color-by-class;
				padding-top: 11vw;
				min-height: 37vw;
				max-height: 535px;
				@include respond(small){
					padding-top: 41vw;
				}

				@include respond(large){
					padding-top: 14vw;
				}
				a{
					margin: 0 10px 20px;
				    position: relative;
				    z-index: 2;
				}
			}

			.itemContent {
				@extend %color-by-class;
				background-color: $white;
				clear: both;
				float: none;
				border-top: 2px solid #D9DBDF;
				margin-top: -5px;

				&:after {
					content: " ";
					display: table;
					clear: both;
				}

				@include respond(largeUp){
					border-top: none;
				}

				a{
					color: $gray-dark;
					@extend %color-by-class;
					box-sizing: border-box;
					display: block;
					float: left;
					width: 16.66%;
					height: 280px;
					border-right: 2px solid #D9DBDF;
					border-bottom: 2px solid #D9DBDF;
					font-family: $titillium;
					font-size: 14px;
					text-transform: uppercase;
					text-align: center;
					vertical-align: middle;
					position: relative;
					background-color: $white;

					@include respond(largeUp){
						border-bottom: none;
					}


					@include respond(small){
						width: calc(100% / 2);
						height: 66px;
					}
					@include respond(large){
						height: 156px;
					}

					&:first-child{
						font-size: 20px;
						box-sizing: border-box;

						.headers-wrapper {
							width: 183px;
							position: absolute;
							top: 50%;
							left: 50%;
							vertical-align: middle;
							transform: translate(-50%, -50%);

							@include respond(large){
								width: 101px;
							}

							@include respond(small){
								width: 112px;
								line-height: 100%;
							}

							div {
								text-align: left;
								line-height: 100%;
								font-family: $oswald;

								@include respond(large){
									font-size: 11px;
								}

								@include respond(small){
									font-size: 12px;
									font-weight: bold;
									display: block;
									text-align: center;
								}
							}
						}

						h1, h2, h3 {
							@extend %color-by-class;
							line-height: 100%;
						}

						h1{
							float: left;
							font-size: 78px;
							font-weight: bold;

							@include respond(large){
								font-size: 43px;
							}

							@include respond(small){
								float: none;
								font-size: 12px;
								display: inline;
								padding: 0 2px 0 0;
							}
						}
						h2{
							display: inline;
							font-size: 35px;

							@include respond(large){
								font-size: 19px;
							}

							@include respond(small){
								font-size: 12px;
								display: inline;
								padding: 0;
							}
						}
						h3 {
							font-size: 32px;
							position: static;
							font-family: $oswald;
							width: auto;
							margin: 4px 0 5px 0;

							@include respond(large){
								font-size: 18px;
								margin: 2px 0 -2px 0;
							}

							@include respond(small){
								font-size: 12px;
								display: inline;
								padding: 0;
							}

							&:first-child {
								float: left;
								display: inline;
								margin-top: 23px;

								@include respond(large){
									margin-top: 13px;
								}

								@include respond(small){
									font-size: 12px;
									display: inline;
									padding: 0;
									float: none;
								}
							}

							span {
								@extend %color-by-class;
								font-weight: 400;

								@include respond(small){
									font-weight: bold;
									padding-left: 2px;
								}
							}
						}
					}

					&:last-child {
						border-right: 0;
					}

					&:hover{
						img{
							opacity: 0.9;
						}
					}

					&.odd {
						@include respond(small){
							border-right: none;
						}
					}

					img{
						display: inline-block;
						max-width: 100%;
						vertical-align: middle;
						opacity: 1;

						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						@include respond(small){
							left: 0;
						    transform: translate(6%, -50%);
							max-width: 50%;
							max-height: 90%;
						}

						@include respond(large){
							max-width: 65%;
							top: 42%;
						}
					}

					h3{
						font-family: $titillium;
						font-size: 14px;
						vertical-align: baseline;
						width: 100%;
						text-align: center;
						position: absolute;
						bottom: 4%;

						a:hover &{
							text-decoration: underline;
						}

						@include respond(small){
							bottom: 20px;
							right: initial;
							text-align: left;
							padding-left: 60%;
							margin-bottom: 0;
							margin-top: 0;
							font-size: 11px;
						}
						@include respond(medium){
							bottom: 20px;
							right: initial;
							text-align: left;
							padding-left: 60%;
							margin-bottom: 0;
							margin-top: 0;
						}

						@include respond(large){
							font-size: 11px;
							bottom: 0;
							margin: 0 0 12px 0;
						}
					}
				}
			}
		}
		&#home-full-2{
			text-align: center;
			background-color: $white;
			@extend %color-by-class;

			h2{
				color: $gray-dark;
				@extend %color-by-class;
				font-family: $oswald;
				font-size: 40px;
				font-weight: bold;
				line-height: 2em;
				padding-top: 1em;
				letter-spacing: .1em;

				@include respond(small){
					font-size: 20px;
					margin-bottom: 0px;
					font-weight: bold;
				}
				@include respond(medium){
					font-size: 20px;
					margin-bottom: 0px;
					font-weight: bold;
				}
			}
			.tabContent{
				@extend %color-by-class;
				display: none;
				background-color: $gray-white;
				&.selected{
					display: block;
				}

				@include respond(small){
					height: 92vw;
					margin-top: 0;
				}

				.leftCarousel, .byGenderMen, .byGenderWomen{
					display: inline-block;
					height: 40vw;
					float: left;
					margin-top: 13px;
					@include respond(small){
						float: none;
						margin-top: 0;
					}
				}
				.byGenderMen, .byGenderWomen{
					@include respond(small){
						height: initial;
					}
				}
				.leftCarousel{
					width: 50%;
					text-align: center;
					background: $gray-white;
					@include respond(small){
						width: 100%;
						height: 71vw;
						display: block;
					}
					.slick-slide{
						display: block;
						list-style: none;
						position: relative;
						height: 39.5vw;
						background: $gray-white;
						@include respond(small){
							height: 88vw;
						}
					}

					.slick-slider{
						padding: 0;
						margin: 0;

						.slick-list{
							height: 100%;
							@include respond(small){
								min-height: auto;
							}
						}
					}
					.slick-arrow{
						height: 30px;
						width: 19px;
						top: 50%;
						margin-top: -15px;

						&.slick-prev{
							background-image: url('../images/lCarouselLite.svg');
							left: 25px;
							@include respond(small){
								left: 15px;
							}
						}

						&.slick-next{
							background-image: url('../images/rCarouselLite.svg');
							right: 25px;
							@include respond(small){
								right: 15px;
							}
						}
					}


					img{
						display: inline-block;
						width: 30vw;
						padding-top: 11vw;

						@include respond(small){
							position: relative;
							top: 16vw;
							padding-top: 0;
							width: 69vw;
							left: 0;
						}
					}
					label{
						position: relative;
						top: 9vw;
						text-transform: uppercase;
						font-family: $titillium;
						font-size: 14px;
						letter-spacing: .1em;

						@include respond(small){
							font-size: 12px;
							top: 27vw;
						}
					}
				}
				.byGenderMen, .byGenderWomen{
					position: relative;
					width: 25%;
					display: inline-block;
					text-align: center;
					overflow: hidden;
					@include respond(small){
						position: initial;
						display: inline-block;
						width: initial;
					}

					img{
						height: 100%;
						@include respond(small){
							display: none;
						}
					}
					button{
						display: inline-block;
						position: absolute;
						top: 35vw;
						left: 50%;
						margin-left: -4vw;
						@include respond(small){
							top: 0;
							border-color: $black;
							color: $black;
							margin: 0 2vw;
							position: relative;
							left: 0;
							&:hover{
								border-color: $black;
								color: $white;
								background-color: $black;
							}
						}
					}
				}
			}
		}

		&#home-full-bestSellerCarousel{
			@extend %color-by-class;
			h2{
				color: $gray-dark;
				@extend %color-by-class;
				font-family: $oswald;
				font-size: 40px;
				font-weight: bold;
				line-height: 2em;
				padding-top: 1em;
				letter-spacing: .1em;

				@include respond(small){
					font-size: 20px;
					margin-bottom: 0px;
					font-weight: bold;
				}
				@include respond(medium){
					font-size: 20px;
					margin-bottom: 0px;
					font-weight: bold;
				}
			}
			ul#horizontal-carousel{
				padding: 0;
				margin: 0 auto 60px;
				width: 85%;

				li.slick-slide{
					list-style: none;
					display: inline-block;

					.product-tile{
						font-family: $titillium;
						text-transform: uppercase;
						font-size: 14px;
						line-height: 1.25em;
						@include respond(large){
							font-size: 12px;
						}
						@include respond(small){
							font-size: 12px;
							padding: 0 10px;
						}
						&:hover{
							.product-image{
								opacity: 0.9;
							}
						}
						.product-image{
							opacity: 1;
							img{
								display: inline;
								width: 195px;
							}
						}
						.product-promo{
							display: none;
						}
						.product-name{
							height: initial;
							margin: 0;
							color: $black;
							overflow: visible;

							a{
								font-size: 16px;
								color: $black;
								margin-bottom: 5px;
								display: inline-block;
								text-transform: none;
								line-height: 18px;
								&:hover{
									text-decoration: underline;
								}
								@include respond(large){
									font-size: 12px;
								}
								@include respond(small){
									font-size: 12px;
								}
							}
						}
						.product-pricing{
							margin: 0;
							color: $gray-warm;
							margin: 0 0 10px;
							.product-sales-price{
								color: $gray-warm;
							}
							.product-regular-price{
								text-decoration: line-through;
								& + .product-sales-price{
									color: $red;
								}
							}
							.product-standard-price{
								& + .product-sales-price{
									color: $red;
								}
							}
						}
						.pr-clp-raing{
							text-align: center;

							img{
								display: inline;
							}
						}
					}
				}
				.slick-arrow{
					width: 19px;
					height: 30px;
					top: 50%;
					margin-top: -15px;
					&.slick-prev{
						background-image: url('../images/lCarouselLite.svg');
						left: -50px;
						@include respond(small){
							left: -17px;
						}
					}
					&.slick-next{
						background-image: url('../images/rCarouselLite.svg');
						right: -50px;
						@include respond(small){
							right: -17px;
						}
					}
				}
			}
		}
		&#home-full-4{
			@extend %color-by-class;
			.bannerContent{
				text-align: left;
				color: $white;
				@extend %color-by-class;
				padding: 12vw 9vw;
				letter-spacing: 1px;
				height: 39.7vw;
				@include respond(large){
					padding: 11vw 9vw;
				}
				@include respond(small){
					padding-top: 48vw;
					padding-left: 0;
					padding-right: 0;
					padding-bottom: 0;
					text-align: center;
					left: 0;
					color: $gray-warm;
					@include color-by-class();
					background-color: $white;
					height: auto;
				}

				h1, h3{
					text-align: left;
					color: $white;
					@extend %color-by-class;
					line-height: 1.25em;
					letter-spacing: 2px;
					text-transform: uppercase;
					@include respond(small){
						color: $gray-warm;
						text-align: center;
					}
				}
				h3{
					font-size: 3.2vw;
					line-height: 1em;
					font-family: $oswald;
					font-weight: bold;
					text-align: right;
					float: right;
					margin: 0;
					clear: both;
					@include respond(small){
						float: none;
						text-align: center;
						max-width: 100%;
						font-size: 4.37vw;

					}
				}
				h1{
					line-height: 1.25em;
					letter-spacing: .1em;
					font-size: 5.6vw;
					text-align: right;
					float: right;
					clear: both;
					@include respond(small){
						float: none;
						text-align: center;
						max-width: 100%;
						color: $gray-dark;
						font-size: 7.6vw;
					}
				}
				p{
					@extend %color-by-class;
					//max-width: 40%;
					font-size: 14px;
					float: right;
					text-align: right;
					clear: both;

					@include respond(small){
						float: none;
						text-align: center;
						max-width: 100%;
						padding: 0 1em;
						//font-size: 4.37vw; //4.37vw = approx 14px at 320px viewport
					}

					button{
						margin: 1.5em 0 0 1.5em;
						display: inherit;
						border-color: $white;
						color: $white;
						@extend %color-by-class;
						&:hover{
							color: $black;
							background-color: $white;
						}

						@include respond(small){
							//margin: 1.5em auto;
							color: $black;
							border-color: $black;

							&:hover{
								color: $white;
								background-color: $black;
							}
						}
					}
				}
			}
		}
		&#home-full-5{
			color: $gray-warm;
			@extend %color-by-class;
			text-align: justify;
			padding: 60px 0;
			font-family: $oswald;
			text-align: center;
			text-transform: uppercase;
			@include respond(small){
				padding: 30px 0;
			}

			h4{
				@extend %color-by-class;
				text-align: center;
				font-size: 20px;
				font-weight: 100;
				line-height: 1em;
				letter-spacing: .1em;
				margin: 0;

				@include respond(small){
					font-size: 14px;
				}
				@include respond(medium){
					font-size: 14px;
				}
			}
			h1{
				text-align: center;
				color: $gray-dark;
				@extend %color-by-class;
				font-size: 70px;
				line-height: 1.1em;
				font-weight: bold;
				letter-spacing: .1em;

				@include respond(small){
					font-size: 30px;
					letter-spacing: .1em;
				}
			}
			img{
				display: inherit;
				margin: 0 auto;
				width: 70%;
				max-width: 1000px;

				@include respond(small){
					width: auto;
					max-width: 100%;
				}
			}
			button{
				@extend .black;
				@extend %color-by-class;
				margin: 0 10px 3em;
				&:hover{
					border-color: $black;
				}
			}
			.myjoys-ticker {
				@extend %color-by-class;
				width: 70%;
				margin: 0 auto;
				@include respond(small){
					width: 70%;
				}

				.spotlight_title, .combinations, .spotlight_link {
					display: none !important;
				}

				ul{
					width: 100%;
					padding-left: 0;

					.slick-arrow{
							width: 19px;
							height: 30px;
							top: 50%;
							margin-top: -15px;

						&.slick-prev{
							background-image: url('../images/lCarouselLite.svg');
							left: -35px;
						}
						&.slick-next{
							background-image: url('../images/rCarouselLite.svg');
							right: -35px;
						}

					}

					li{
						float: left;
						box-sizing: border-box;
						list-style: none;
						color: $gray-warm;
						font-family: $titillium;
						font-size: 12px;
						line-height: 120%;
						padding: 0 .5em;
						width: 100%;

						@include respond(small){
							text-align: left;
						}
						img{
							display: block;
							width: 100%;
						}
					}
				}
			}
		}
		&#home-full-6{
			@extend %color-by-class;
			div,
			article {
				@extend %color-by-class;
				box-sizing: border-box;
				width: 50%;
				height: 28.9vw;
				float: left;
				padding-left: 28vw;
				padding-right: 4vw;
				justify-content: center;
				text-align: center;
				vertical-align: middle;
				line-height: 1.5em;
				position: relative;
				z-index: 1;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				@include respond(small){
					padding: 0;
					width: 100%;
					height: 92vw;
				}

				img{
					position: absolute;
					top: 0px;
					left: 0px;
					height: 100%;
					z-index: -1;
					@include respond(small){
						position: relative;
						height: auto;
						width: 100%;
					}
				}
				button{
				@extend %color-by-class;
					display: block;
					margin: 0 auto;
				}
				h3, h4{
					vertical-align: middle;
					color: $white;
					@extend %color-by-class;
					text-transform: uppercase;
					font-family: $oswald;
					line-height: 1.2em;
					margin: 0;
				}
				button{
					@extend %color-by-class;
					vertical-align: middle;
					margin-top: 1em;
					margin-bottom: 1em;
					@include respond(small){
						margin-top: 0;
					}
				}
				h3{
					font-size: 2vw;
					letter-spacing: .1em;
					margin-bottom: 1rem;
					@include respond(small){
						font-size: 6vw;
						line-height: 2em;
						margin-bottom: 0;
					}
				}
				h4{
					font-size: 1.5vw;
					font-weight: 100;
					@include respond(small){
						font-size: 4vw;
					}
				}
				&.blockOne{
					color: $black;
					@extend %color-by-class;

					h3, h4{
						color: $black;
						@extend %color-by-class;
					}
				}
				&.blockTwo{
					color: $white;
					@extend %color-by-class;
					background-color: $black;

					@include respond(small){
						color: $black;
						background-color: $white;
						h3{
							color: $black;
						}

						button{
							color: $black;
							border-color: $black;

							&:hover{
								color: $white;
								border-color: $black;
								background-color: $black;
							}
						}
					}
				}
				&.blockThree{
					color: $white;
					@extend %color-by-class;
					background-color: $black;

					@include respond(small){
						padding-top: 7em;

						img{
							display: none;
						}
					}
					button{
						&:hover{
							border-color: $white;
						}
					}
				}
				&.blockFour{
					color: $white;
					@extend %color-by-class;
					background-color: #82B5D1; //this blue isn't in style guide
					@include respond(large){
						padding-top: 2vw;
					}
					@include respond(small){
						padding-top: 5em;

						img{
							display: none;
						}
						button{
							margin-top: 2em;
						}
					}
				}
			}
		}
		&#home-full-7{
			@extend .innerWidth;
			@extend %color-by-class;
			padding: 4em 0 0;

			@include respond(small){
				text-align: center;
			}

			h4{
				font-family: $oswald;
				font-weight: 100;
				text-transform: uppercase;
				color: $gray-warm;
				@extend %color-by-class;
				font-size: 20px;
				line-height: 1em;
				margin-top: 0;
				margin-bottom: 1em;
				letter-spacing: .1em;

				@include respond(small){
					font-size: 14px;
					margin-bottom: .5em;
				}
			}
			#ytd, #featuredPlayers{
				@extend %color-by-class;
				@include respond(small){
					display: inline-block;
					float: none;
					margin-top: 2em;
					width: 77vw;
				}
				margin-bottom: 4em;
				float: left;
				box-sizing: border-box;
			}
			#ytd{
				width: 32%;
				padding-right: 3.5em;
				margin-right: 3.5em;
				border-right: 2px solid $gray-light;
				img{
					width: 100%;
				}

				@include respond(small){
					padding: 0;
					margin: 0;
					border: 0;
					text-align: center;
					width: 77vw;

					img{
						width: 73vw;
					}
				}
			}
			#featuredPlayers{
				width: 62%;
				@include respond(small){
					width: 77vw;
				}
				@include respond(large){
					width: 58%;
				}
				h4{
					text-align: left;
					padding-left: .25em;
					@include respond(small){
						padding-left: 0;
					}
				}
				ul{
					width: 100%;
					padding-left: 0;

					.slick-arrow{
						width: 19px;
						height: 30px;
						top: 50%;
						margin-top: -15px;
						&.slick-prev{
							background-image: url('../images/lCarouselLite.svg');
							left: -35px;
						}
						&.slick-next{
							background-image: url('../images/rCarouselLite.svg');
							right: -35px;
						}
					}

					li{
						float: left;
						box-sizing: border-box;
						list-style: none;
						color: $gray-warm;
						font-family: $titillium;
						font-size: 12px;
						line-height: 120%;
						padding: 0 .5em;
						width: 100%;

						@include respond(small){
							text-align: left;
						}
						img{
							display: block;
							width: 100%;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&#home-full-8{
			@extend %color-by-class;
			background-color: $gray-white;
			h2{
				@extend %color-by-class;
				font-size: 40px;
				padding: 20px 0;
   				margin: 0;
				@include respond(small){
					font-size: 30px;
					padding: 15px 0;
				}
			}
			.spredfast-wrapper {
				padding-bottom: 100px;
				max-width: 1200px;
				margin: 0 auto;
				@include respond(large) {
					max-width: 768px;
				}
			}

		}

		.tabSelector{
			margin: 0 auto 20px;
			@include respond(small){
				margin: 0 auto;
			}

			a{
				font-size: 20px;
				line-height: 1.5em;
				letter-spacing: .1em;
				font-family: $oswald;
				text-transform: uppercase;
				color: #cecece;
				border-bottom: 4px solid transparent;
				margin: 0 50px;
				padding: 9px 0;
				&:hover{
					color: $gray-dark;
				}

				&.selected{
					color: $gray-dark;
					border-color: $gray-dark;
					@include respond(small){
						border-color: $clp-border;
						border-width: 1px;
						padding-bottom: 0px;
						span{
							border-bottom: 4px solid $black;
							display: inline-block;
							padding-bottom: 9px;
						}
					}
				}
				&.mobile{
					display: none;
					@include respond(small){
						display: block;
					}
				}
				&.desktop{
					@include respond(small){
						display: none;
					}
				}
				@include respond(small){
					display: block;
					background-color: $white;
					border-top: 1px solid $clp-border;
					line-height: 2em;
					margin: 0;
				}
			}
		}

		.bannerContent{
			@extend %color-by-class;
			box-sizing: border-box;
			position: relative;
			text-align: center;
			background-size: 100%;
			background-repeat: no-repeat;
			background-position: 0 50%;
			@include respond(small){
				background-position: 0 0;
			}

			h1{
				font-size: 70px;
				color: $gray-dark;
				@extend %color-by-class;
				line-height: 2em;
				text-transform: uppercase;
				z-index: 2;
				position: relative;
				font-family: $oswald;
				font-weight: bold;

				@include respond(small){
					font-size: 30px;
					line-height: 100%;
					margin-bottom: 13px;
				}

				@include respond(large){
					font-size: 30px;
					line-height: 100%;
					margin-bottom: 13px;
				}
			}
			button{
				@extend %color-by-class;
				position: relative;
				z-index: 2;
				margin: 0 10px 20px;

				@include respond(large){
					padding: 0 18px;
				}

				@include respond(small){
					padding: 0 18px;
				}

				&.black:hover{
					border-color: $black;
				}
			}
		}
	}
}

#main{
	@include respond(small){
		border-bottom: 2px solid #e5e5e5;
	}
}

.ytvideo-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
    iframe, object, embed {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
    }
	img {
		max-width: 100%;
	}
}
