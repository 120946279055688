.experience-component.experience-assets-bannerSpotlight {
  picture {
    display: block;
  }

	img {
    	display: block;
    	width: 100%;
  	}

	h2 {
    	padding: 0 10px;
    	font-size: 24px;
    	text-align: center;
	  	margin-bottom: 30px;
  	}

  h3 {
      margin-bottom: 12px;
	  font-size: 18px;

      @media screen and (min-width: $tablet-regular) {
          margin-bottom: 12px;
      }

      @media screen and (min-width: $small-breakpoint) {
          margin-bottom: 12px;
      }
  }

	p {
      	margin-bottom: 12px;
	  	font-size: 14px;
  	}

	.pd-cta-buttons {
		&:nth-of-type(1) {
			margin: 0;
		}
		a {
			font-size: 14px;
			padding: 5px;
			margin: 0 10px 10px 10px;
		  	&:hover {
				text-decoration: none;
			  	background: $black;
			  	color: $white;
		  }
	  }
  }


	.pd-spotlight-tiles-container {
		@include respond(largeUp) {
     	display: flex;
      justify-content: center;
		 	width: calc(100% + 20px);
		 	position: relative;
		}
      .pd-spotlight-tiles {
          margin: 0 10px;

         @include respond(largeUp) {
              width: calc(25% - 20px);
              margin: 0 20px 0 0;
          }
      }
  }

  .pd-banner-spotlight-description {
    padding: 30px 10px 10px;
    text-align: center;
  	@include respond(small) {
		opacity: 0;
		transition: opacity 1.1s ease-in-out;

	}
  }

	.slick-active {
		.pd-banner-spotlight-description {
			@include respond(small) {
				opacity: 1;
			}
		}
	}

  .placeholder_desktop,
  .placeholder_mobile {
    display: none;
  }
}
