@import './mixins/text_crop';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/_variables.scss';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/_mixins.scss';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/_fonts.scss';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/_base_elements.scss';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/_buttons.scss';
@import '../../../../app_footjoy/cartridge/scss/default/footjoy/_content_assets_slots.scss';
@import '../../../../app_footjoy/cartridge/scss/default/footjoy/_homepage.scss';
@import '../../../../app_footjoy_overrides/cartridge/scss/default/footjoy/_homepage.scss';

//PD components styling
$white: #fff;

.experience-component {
    @include respond(largeUp) {
      width: 100%;
      margin: 0 auto;
      max-width: 2200px;
      box-sizing: border-box;
    }

  &.experience-layouts-tabbedLayout,
  &.experience-assets-bannerSpotlight,
  &.experience-layouts-myjoysLayout
    {
      @include respond(largeUp) {
        width: 90%;
        max-width: 1980px;
      }
    }

  font-family: neuzeit-grotesk,sans-serif,serif;

  @media screen and (min-width: $tablet-regular) {
    .text-white {
      color: $white;
    }
  }

  a.button {
    text-decoration: none !important;
  }

  h2 {
    font-size: 42px;
    margin: 0 0 20px;
    font-family: neuzeit-grotesk,sans-serif,serif;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;
    color: $black;

    @media screen and (max-width: 767px) {
      br {
        display: none;
      }
    }

    @media screen and (min-width: $tablet-regular) {
      font-size: 22px;
      margin-bottom: 16px;
    }

    @media screen and (min-width: $small-breakpoint) {
      font-size: 42px;
      margin-bottom: 16px;
    }
  }

  h3 {
    @include text-crop($line-height: 0.88);
    font-size: 18px;
    margin: 0 0 16px;
    text-transform: capitalize;
    font-family: neuzeit-grotesk,sans-serif,serif;
    font-weight: 700;
    letter-spacing: 0;
    color: $black;

    @media screen and (max-width: 767px) {
      br {
        display: none;
      }
    }

    @media screen and (min-width: $tablet-regular) {
      margin-bottom: 14px;
    }

    @media screen and (min-width: $standard-breakpoint) {
      margin-bottom: 16px;
    }
  }

  p {
    @include text-crop($line-height: 1.38);
    font-size: 12px;
    margin-bottom: 20px;
    font-family: neuzeit-grotesk,sans-serif,serif;
    text-transform: none;
    color: $black;

    @media screen and (max-width: 767px) {
      br {
        display: none;
      }
    }
  }

  .pd-cta-buttons {
    display: inline-block;

    &:nth-of-type(1) {
      margin-right: 20px;
      margin-bottom: 20px
    }

    .link {
      font-family: neuzeit-grotesk,sans-serif,serif;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: underline;
      font-weight: 700;
      color: $black;

      &.white {
        color: $white;
      }
    }

    .button {
      font-family: neuzeit-grotesk,sans-serif,serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 42px;
      height: 44px;
      padding: 0 12px;

      &.white {// need to reverse the white button on mobile to the inverted style
        @media screen and (max-width: 767px) {
          background-color: $black;
          color: $white;
          &:hover{
            background-color: $white;
            color: $black;
            border-color: $black;
          }
        }
      }
    }
  }
}

.placeholder_desktop,
.spacer_desktop {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.placeholder_mobile,
.spacer_mobile {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.placeholder_desktop,
.placeholder_mobile {
  overflow: hidden;
  background: #ebebeb;
  width: 100%;
  position: relative;
}

.video-pagedesigner-container {
  position: relative;
  padding: 56% 0 0 0;
}

.cls-image-wrapper {
  position: relative;
  display: block;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}

.membersOnly-dialog-pd {
    top: 0 !important;
    height: 100% !important;
    z-index: 200;

    .members-only-modal {
        max-width: 775px;

        @media screen and (min-width: 880px) {
            display: flex !important;
            margin: auto !important;
            height: auto !important;
        }

        .members-only-content {
            background: $white;
            padding: 5.0625rem 3rem 1.875rem 3.6875rem !important;
            width: 100% !important;
            margin: 0 !important;
            @media screen and (min-width: 880px) {
                text-align: left !important;
                width: 49% !important;
                padding: 5.0625rem 3rem 7.875rem 3.6875rem !important;
            }

            .content-asset {
                margin: 0 !important;
                max-width: 100% !important;
            }
        }
        .members-only-account {
            background-color: #f6f6f6;
            @media screen and (min-width: 880px) {
                width: 50% !important;
                vertical-align: top !important;
                padding-top: 0 !important;
                height: 617px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .ui-accordion-content {
                @media screen and (min-width: 880px) {
                    padding: 0 2rem 3.55rem;
                }
            }

            .account-register-step2 {
                padding-bottom: 3.55rem;
            }

            .account-register {
                @media screen and (max-width: 879px) {
                    height: auto !important;
                }
            }
        }
    }
}

.members-dialog {
    .membersOnly-dialog-pd {
        #membersOnly {
            background-color: rgba(0, 0, 0, 0.7);
            display: block !important;
            @media screen and (min-width: 880px) {
                display: flex !important;
            }
        }
    }
}

.pt_pagedesigner {
    .members-only-modal {
        display: none !important;
    }
}
/**
* Page Designer Components:
* - Hero Banner
* - 2up Banner
**/
@import "components/hero_banner";
@import "components/2up_banner";
@import "components/banner_insider";
@import "components/tabs";
@import "components/banner_spotlight";
@import "components/my_joys";
@import "components/pd_product_tile";
