.experience-component.experience-assets-bannerInsider {
    h2,
    h3,
    p,
    .link {
        color: $white;
    }

    h2 {
        margin-bottom: 26px;

        @media screen and (min-width: $tablet-regular) {
            margin-bottom: 20px;
        }

        @media screen and (min-width: $small-breakpoint) {
            margin-bottom: 26px;
        }
    }

    h3 {
        margin-bottom: 24px;

        @media screen and (min-width: $tablet-regular) {
            margin-bottom: 18px;
        }

        @media screen and (min-width: $small-breakpoint) {
            margin-bottom: 24px;
        }
    }

    p {
        margin-bottom: 25px;

        @media screen and (min-width: $tablet-regular) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: $small-breakpoint) {
            margin-bottom: 25px;
        }
    }

    .pd-banner-description {
        background: #243e37;
        position: relative;

        .pd-banner-description-inner {
            padding: 50px 20px 30px;
            @media screen and (min-width: 768px) {
                position: absolute;
                padding: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
            }
        }

		.pd-cta-buttons {

			margin-bottom: 15px;

			&:nth-of-type(1) {
				margin-right: 5px;
				margin-left: -5px;
				position: relative;
			}

			&:nth-of-type(2) {
				margin-right: 5px;
			}

			.link {
				padding: 5px;

				&:hover {
					text-decoration: none;
					background: $white;
					color: $black;
				}
			}
		}
    }

    .pd-banner-image-wrapper {
        @media screen and (max-width: 767px) {
              display: none;
        }
    }
}
