.experience-component {
  .pd-banner-image-wrapper {
    position: relative;

    @media screen and (min-width: $tablet-regular) {
      flex-basis: 55%;
    }

    @media screen and (min-width: $small-breakpoint) {
      flex-basis: 60%;
    }

    &.mobile-padding {
      @media screen and (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .img {
      margin-bottom: -3px;
    }
  }

  picture {
    display: block;
  }

  img {
    display: block;
    width: 100%;
  }

  .has-bottom-spacing .cls-image-wrapper {
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .block-banner {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
    }

    &.flip-layout {
      @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
      }
    }
  }

  .overlay-banner {
    position: relative
  }

	.container-banner {
		margin: 0 auto;
	}

  .pd-banner-description {
    background-color: #ececec;

    @media screen and (min-width: $tablet-regular) {
      flex-basis: 45%;
    }

    @media screen and (min-width: $small-breakpoint) {
      flex-basis: 40%;
    }

    .pd-banner-description-inner {
      text-align: center;
      padding: 14% 20px 12%;
      @media screen and (min-width: $tablet-regular) {
        padding: 10% 10px 10px 10%;
        text-align: left;
      }

      @media screen and (min-width: $standard-breakpoint) {
        padding: 24% 10px 10px 10%;
      }

      @media screen and (min-width: $break-xl) {
        padding: 38% 10px 10px 20%;
      }
    }
  }

  .pd-hero-banner-overlay-description {
    text-align: center;
    padding: 14% 20px 12%;

    @media screen and (min-width: $tablet-regular) {
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }
}
